import { splitTextWithUnderline } from "@/helpers/utility";

export function displayNamesNotSupported(): boolean {
  return !(Intl as any).DisplayNames;
}

export function getRegionNameByCountryCode(countryCode: string): string {
  if (!countryCode) {
    return "Global";
  }

  if (displayNamesNotSupported()) {
    return countryCode;
  }
  const regionNames = new (Intl as any).DisplayNames(["en"], {
    type: "region",
  });

  return regionNames.of(countryCode);
}

export function getRegionNameByLocale(locale: string): string {
  const countryCode = splitTextWithUnderline(locale)[1];
  return getRegionNameByCountryCode(countryCode);
}

export function getLanguageNameByLanguageCode(languageCode: string): string {
  if (displayNamesNotSupported()) {
    return languageCode;
  }
  const languageNames = new (Intl as any).DisplayNames(["en"], {
    type: "language",
  });

  return languageNames.of(languageCode);
}

export function getLanguageNameByLocale(locale: string): string {
  const languageCode = splitTextWithUnderline(locale)[0];
  return getLanguageNameByLanguageCode(languageCode);
}

export function getFlagCodeByCountryCode(countryCode: string): string {
  return countryCode === "GB" ? "GB-UKM" : countryCode;
}

export function getFlagCodeByLocale(locale: string): string {
  const countryCode = splitTextWithUnderline(locale)[1];
  return getFlagCodeByCountryCode(countryCode);
}
