import { LegacyProfile, Profile } from "@/types";

export const mapLegacyProfile = (legacyProfile: LegacyProfile): Partial<Profile> => {
  return {
    profileCmsId: legacyProfile.profileCmsId,
    uuid: legacyProfile.uuid ?? null,
    real_name: legacyProfile.real_name ?? "",
    is_smoker: legacyProfile.smoke === "Yes",
    hobbies: legacyProfile.hobbies ?? "",
    has_piercing: legacyProfile.piercing === "Yes",
    has_tattoo: legacyProfile.tattoo === "Yes",
    hair_colour: legacyProfile.hairColor ?? "",
    civil_status: legacyProfile.civilStatus ?? "",
    eye_colour: legacyProfile.eyeColor ?? "",
    about_me: legacyProfile.aboutMe ?? "",
    about_you: legacyProfile.aboutYou ?? "",
    adult_level: legacyProfile.adultLevel ?? "",
    age: +legacyProfile.age ?? null,
    build: legacyProfile.build ?? "",
    status: legacyProfile.status ?? "",
    length: legacyProfile.lengthChoice ?? "",
    profession: legacyProfile.profession ?? "",
    transportation: legacyProfile.transportation ?? "",
    housing_situation: legacyProfile.habitat ?? "",
    metadata: {
      length: +legacyProfile.lengthChoice ?? null,
      profession: legacyProfile.profession ?? "",
      transportation: legacyProfile.transportation ?? "",
      housing_situation: legacyProfile.habitat ?? "",
      passion: null,
      sexual_orientation: null,
    },
    is_angel: legacyProfile.fake === 1,
    avatar: legacyProfile.photo ?? "",
    domain: legacyProfile.site ?? "",
    geo_iso: legacyProfile.geoIso ?? "",
    geo_latitude: legacyProfile.geoLatitude ?? "",
    geo_longitude: legacyProfile.geoLongitude ?? "",
    geo_free: legacyProfile.geoFree,
    geo_language: legacyProfile.geoLanguage ?? "",
    geo_locality: legacyProfile.geoLocality ?? "",
    id: legacyProfile.id ?? "",
    foreignProfileId: legacyProfile.foreignProfileId,
    uid: legacyProfile.id?.toString() ?? "",
    name: legacyProfile.name ?? "",
    date_of_birth:
      typeof legacyProfile.birthdate === "string" ? legacyProfile.birthdate : legacyProfile.birthdate?.date || "",
    base_profile_id: +legacyProfile.profileCmsId ?? null,
    gender: legacyProfile.gender === "m" ? "male" : "female",
    created_at: legacyProfile.created ?? "",
    updated_at: legacyProfile.modified ?? "",
    geo: [
      {
        iso: legacyProfile.geoIso ?? "",
        language: legacyProfile.geoLanguage ?? "",
        timezone: legacyProfile.geoTimezone ?? "",
        region1: legacyProfile.geoRegion1 ?? "",
        region2: legacyProfile.geoRegion2 ?? "",
        region3: legacyProfile.geoRegion3 ?? "",
        region4: legacyProfile.geoRegion4 ?? "",
        locality: legacyProfile.geoLocality ?? "",
        postcode: legacyProfile.geoPostcode ?? "",
      },
    ],
    creditsBalance: +legacyProfile.creditsBalance ?? null,
    last_activity: legacyProfile.lastActivity ?? null,
    profile_type: legacyProfile.profileType ?? null,
  };
};
