import data from "emoji-mart-vue-fast/data/all.json";
import { EmojiIndex, EmojiView } from "emoji-mart-vue-fast";
import emojiRegex from "emoji-regex/RGI_Emoji";
import { LegacyAttachment, Profile, DomainConfiguration } from "@/types";
import { VARIABLE_REGEX } from "@/constants";
import { default as dayjs } from "dayjs";

const emojiIndex = new EmojiIndex(data);
const nullableValues: any[] = ["", undefined];

export const mapTake =
  (...properties: string[]) =>
  (source: any) =>
    properties.reduce((acc, property) => {
      acc[property] = nullableValues.includes(source[property]) ? null : source[property];
      return acc;
    }, {} as any);

/* eslint-disable */
export const pipe =
  (...fns: any) =>
  (x: any) =>
    fns.reduce((v: any, f: any) => f(v), x);

export const awaitPipe =
  (...fns: any) =>
  (x: any) =>
    fns.reduce(async (v: any, f: any) => await f(v), x);
/* eslint-enable */

export const stringToHex = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const humanize = (val: string): string => {
  if (typeof val === "string" && val) {
    return val
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/[_\s]+/g, " ")
      .replace(/^[a-z]/, (m: string) => m.toUpperCase());
  }

  return val;
};

export const chunkArray = (array: any[], chunks: number): any[] => {
  const chunkedArray = [];
  const mid = Math.ceil(array.length / chunks);
  for (let col = 0; col < chunks; col++) {
    chunkedArray.push(array.slice(col * mid, col * mid + mid));
  }
  return chunkedArray;
};

export const iconMapping = {
  eye_colour: "mdi-eye",
  hair_colour: "mdi-face-profile",
  is_smoker: "mdi-smoking",
  has_tattoo: "mdi-sticker",
  has_piercings: "mdi-paperclip",
  age: "mdi-counter",
  date_of_birth: "mdi-calendar",
  civil_status: "mdi-card-account-details",
  housing_situation: "mdi-home",
  sexual_orientation: "mdi-gender-transgender",
  transportation: "mdi-car",
  location: "mdi-earth",
  location_origin: "mdi-map-marker",
  passion: "mdi-fire",
  profession: "mdi-briefcase",
  name: "mdi-tag",
  real_name: "mdi-tag-heart",
  hobbies: "mdi-racquetball",
  about_me: "mdi-text-subject",
  about_you: "mdi-text",
  adult_level: "mdi-badge-account-alert",
  appearance: "mdi-mirror",
  build: "mdi-dumbbell",
  length: "mdi-ruler",
  status: "mdi-checkbox-marked",
};

/**
 * Calculates the age based on the date of birth.
 *
 * @param {string} dateOfBirthString - The date of birth as a string (e.g., "1989-06-21 00:00:00+00").
 * @returns {number} - The calculated age.
 */
export function calculateAge(dateOfBirthString: string): number {
  const today = dayjs();
  const birthDate = dayjs(dateOfBirthString);

  return today.diff(birthDate, "year");
}

export const mapLegacyAttachments = (attachments: LegacyAttachment[]) => {
  return attachments.map((attachment: LegacyAttachment) => ({
    id: attachment.id,
    url: attachment.path,
    displayUrl: attachment.path,
  }));
};

/**
 * Flattens filters with array-like keys into structured arrays.
 * Example: { "hairColor[0]": "blond", "hairColor[1]": "brown" }
 *          => { "hairColor": ["blond", "brown"] }
 *
 * @param filters - An object with potentially array-like keys.
 * @returns A flattened object with structured arrays.
 */
export const flattenArrayLikeKeys = (
  filters: Record<string, unknown>,
): Record<string, unknown> => {
  const flattened: Record<string, unknown> = {};
  const arrayMap: Record<string, Record<number, unknown>> = {};

  // Single regex with optional capture group for array index:
  //   ^filters\[(?<prop>[^\]]+)\]      -> "filters[someKey]"
  //   (?:\[(?<index>\d+)\])?          -> optionally "[someNumber]"
  const filtersKeyPattern = /^filters\[(?<prop>[^\]]+)](?:\[(?<index>\d+)])?$/;

  for (const [key, value] of Object.entries(filters)) {
    const match = filtersKeyPattern.exec(key);

    if (match?.groups?.prop) {
      const prop = match.groups.prop;
      if (match.groups.index !== undefined) {
        const idx = parseInt(match.groups.index, 10);
        if (!arrayMap[prop]) {
          arrayMap[prop] = {};
        }
        arrayMap[prop][idx] = value;
      } else {
        flattened[prop] = value;
      }
    } else {
      flattened[key] = value;
    }
  }

  // Now, transform arrayMap entries into actual arrays (contiguous).
  for (const prop of Object.keys(arrayMap)) {
    const indexValuePairs = Object.entries(arrayMap[prop]);
    indexValuePairs.sort((a, b) => Number(a[0]) - Number(b[0]));
    flattened[prop] = indexValuePairs.map(([_, val]) => val);
  }

  return flattened;
};

export function emojiToHtml(emoji: any): string {
  const style = `background-position: ${emoji.getPosition()}`;
  const emojiView = new EmojiView(emoji, emoji.skin, "twitter", null, null, 16);

  if (emojiView.canRender) {
    return `<img data-text="${emoji.native}" alt="${
      emoji.colons
    }" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="emoji-text ${emojiView
      ._cssClass()
      .reduce((acc: string, curr: string) => acc + " " + curr)}" style="${style}; height: 16px;">`;
  }
}

export function replaceColonEmojisInString(str: string): string {
  const COLONS_REGEX = new RegExp("([^:]+)?(:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?)", "g");

  return str.split(COLONS_REGEX).reduce((acc: string, curr: string) => {
    if (!curr) {
      return acc;
    }
    if (curr.startsWith(":skin")) return acc;
    const match = curr.match(COLONS_REGEX);

    if (match && match[0]) {
      const emoji = emojiIndex.findEmoji(match[0]);

      if (emoji) {
        return acc + emoji.native;
      }
    }

    return acc + curr;
  }, "");
}

export function transformMessageContentWithEmojis(content: string): string {
  if (!content) {
    return;
  }
  return replaceColonEmojisInString(content);
}

/**
 * Replace %[playerCity]% and %[playerProvince]% in message by Profile location
 */
export function replaceGeoWizardVariables({
  profile,
  message,
  domainConfiguration,
}: {
  profile: Profile;
  message: string;
  domainConfiguration: DomainConfiguration;
}): string {
  if (!domainConfiguration?.geoWizardEnabled) {
    return message;
  }

  return message.replace(VARIABLE_REGEX, (_i: string, item: string) => {
    return (
      {
        "%[playerProvince]%": profile?.geo[0]?.[domainConfiguration.geoStrategyRegionField || "region1"],
        "%[playerCity]%": profile?.geo[0]?.[domainConfiguration.geoStrategyCityField || "locality"],
      }[item] ?? ""
    );
  });
}

export function replaceUnicodeEmojisInString(str: string) {
  const regex = emojiRegex();

  let match;
  while ((match = regex.exec(str))) {
    const emoji = match[0];
    const emojiObj = emojiIndex.findEmoji(match[0]);
    str = str.replace(emoji, emojiObj?.colons || "");
  }

  return str;
}

export const isPWA = () => {
  return window && window.matchMedia("(display-mode: standalone)").matches;
};

export const pokeVariableRegex = /(%\[.*?\]%)/gi;
export const pokeVariableNameRegex = /%\[(.*)]%/i;

export function computePokeStatScore(paidReplies: number, reached: number): number {
  return Number(((paidReplies / reached) * 100 || 0).toFixed(3));
}

export function splitTextWithUnderline(text: string): string[] {
  return text.split("_");
}