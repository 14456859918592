import { Timestamp } from "firebase/firestore";
import { default as dayjs } from "dayjs";
import { default as UTC } from "dayjs/plugin/utc";
import { default as Timezone } from "dayjs/plugin/timezone";
import { default as relativeTime } from "dayjs/plugin/relativeTime";

dayjs.extend(UTC);
dayjs.extend(Timezone);
dayjs.extend(relativeTime);

type DayjsDiffUnit =
  | 'm'
  | 'hours'
  | 'date'
  | 'month'
  | 'millisecond'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'year'
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'days'
  | 'months'
  | 'years'
  | 'dates'
  | 'week'
  | 'w';

export function makeDataUnbreakable(inputDate: string): string {
  return inputDate
    .trim()
    .replace(/\s(?=\S*$)/, "&nbsp;")
    .replace(/\s(?=\S*$)/, "&nbsp;");
}

export function displayDateTime(dateTime: Date): string {
  if (!dateTime) {
    return "";
  }

  const todayBeginning = dayjs().startOf("day");
  const dateTimeJs = dayjs(dateTime);
  const result = [`<strong>${dateTimeJs.format("HH:mm")}</strong>`];
  if (dateTimeJs < todayBeginning) {
    const UNBREAKABLE_DASH = "&#8209;";
    result.push(" " + dateTimeJs.format(`DD${UNBREAKABLE_DASH}MMM${UNBREAKABLE_DASH}YYYY`));
  }
  result.push(" — " + dateTimeJs.fromNow().replace(/ /g, "&nbsp;"));
  return result.join("");
}

export function displayChatMessageDateTime({
  dateTime,
  customerTimezone,
  profileTimezone,
  isShort,
}: {
  dateTime: Timestamp;
  customerTimezone: string;
  profileTimezone: string;
  isShort: boolean;
}): string {
  if (!dateTime) {
    return "";
  }

  const createdAtDayjs = dayjs(dateTime.seconds * 1000);
  const customerDateTimeDayjs = dayjs(dateTime.seconds * 1000).tz(customerTimezone || "UTC");
  const profileDateTimeDayjs = dayjs(dateTime.seconds * 1000).tz(profileTimezone || "UTC");
  const customerTimeDisplay = customerDateTimeDayjs.format("HH:mm");
  const profileTimeDisplay = profileDateTimeDayjs.format("HH:mm");
  const haveSameTimezone = customerTimezone === profileTimezone;

  const dayFormat = isShort ? "MMM D" : "ddd, MMM D, YYYY";
  const haveSameDay = customerDateTimeDayjs.day() === profileDateTimeDayjs.day();
  const customerDateDisplay = haveSameDay ? "" : customerDateTimeDayjs.format(dayFormat);
  const profileDateDisplay = profileDateTimeDayjs.format(dayFormat);

  const result = [];

  result.push('<strong class="blue--text text--darken-2">');
  result.push(customerTimeDisplay);
  result.push("</strong>");
  if (!haveSameTimezone) {
    result.push(` ${customerDateDisplay}`);
    result.push(" | ");
    result.push('<strong class="purple--text text--accent-4">');
    result.push(profileTimeDisplay);
    result.push("</strong>");
  }
  result.push(` ${profileDateDisplay}`);
  result.push((isShort ? " (" : " — ") + createdAtDayjs.fromNow().replace(/ /g, "&nbsp;") + (isShort ? ")" : ""));

  return result.join("");
}

export function displayUsedAttachmentDateTime({
  dateTime,
  profileTimezone,
}: {
  dateTime: Timestamp;
  profileTimezone: string;
}): string {
  if (!dateTime) {
    return "";
  }

  const profileDateTimeDayjs = dayjs(dateTime.seconds * 1000).tz(profileTimezone || "UTC");

  const profileDateDisplay = profileDateTimeDayjs.format("ddd, MMM D, YYYY");
  const profileTimeDisplay = profileDateTimeDayjs.format("HH:mm");

  return ` ${profileDateDisplay}, at ${profileTimeDisplay}`;
}

export function displayAgeFromBirthDate(date: any): number {
  const today = new Date();
  const oneYearInMiliseconds = 3.15576e10;
  return Math.floor(((today as any) - new Date(date).getTime()) / oneYearInMiliseconds);
}

export function recalculateSecondsToRealTime(seconds: number): Record<string, number> {
  return {
    days: Math.floor(seconds / (3600 * 24)),
    hours: Math.floor((seconds % (3600 * 24)) / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
    seconds: Math.floor(seconds % 60),
  };
}

export function roundSecondsCalculatedToReadableTime(seconds: number): Record<string, any> {
  const finalRoundedTime = {
    unit: "seconds",
    valueNumber: 0,
  };

  const readableTime = recalculateSecondsToRealTime(seconds);
  // leave only items with values higher than 0
  for (const value in readableTime) {
    if (readableTime[value] === 0) delete readableTime[value];
  }
  const readableTimeKeys = Object.keys(readableTime);
  const readableTimeValues = Object.values(readableTime);
  const firstKey = readableTimeKeys[0];

  // return default when value was not set before
  if (readableTimeKeys.length === 0) return finalRoundedTime;

  finalRoundedTime.unit = firstKey;
  if (readableTimeKeys.length === 1) {
    finalRoundedTime.valueNumber = readableTime[firstKey];
    return finalRoundedTime;
  }
  // Round the time

  if (firstKey === "days") {
    const daysKey = firstKey;
    const hoursKey = readableTimeKeys[1];
    const daysValue = (readableTime as any)[daysKey][0];
    const hoursValue = (readableTime as any)[hoursKey][1];

    const threshold = daysKey === "days" && hoursKey === "hours" ? 12 : 30;

    finalRoundedTime.valueNumber = hoursValue <= threshold ? daysValue : daysValue + 1;
  } else {
    finalRoundedTime.valueNumber = readableTimeValues[1] <= 30 ? readableTimeValues[0] : readableTimeValues[0] + 1;
  }

  return finalRoundedTime;
}

export function recalculateReadableTimeToSeconds(readableTime: string): number {
  const timeValue = parseInt(readableTime.slice(0, readableTime.indexOf(" ")));
  const timeUnit = readableTime.slice(readableTime.indexOf(" ") + 1);
  const secondsInDay = 86400;
  const secondsInHour = 3600;
  const secondsInMinute = 60;
  let numberOfSeconds = 0;
  numberOfSeconds = {
    DAY: timeValue * secondsInDay,
    HOUR: timeValue * secondsInHour,
    MINUTE: timeValue * secondsInMinute,
    SECOND: timeValue,
  }[timeUnit] as number;
  return numberOfSeconds;
}

export function formatExactTime(date: Date, format: string): string {
  return dayjs(date).format(format);
}

export function getDiffMinutes(date: Date, format: DayjsDiffUnit): number {
  return dayjs().diff(date, format);
}