import axios, { AxiosInstance } from "axios";
import qs from "qs";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { TargetAutoCompleteItem, Profile, GeoFreeLocationApiResponse, ProfileConnector, Attachment } from "@/types";
import { ProfileMapper } from "@/helpers/profileMapper";
import { extractAttachmentCdnHash } from "@/helpers/attachment";
import { flattenArrayLikeKeys } from "@/helpers/utility";

const baseURL = process.env.VUE_APP_API_BASE_ENDPOINT;

export default class ProfileApiConnector implements ProfileConnector {
  private repository: AxiosInstance;
  private profileMapper: ProfileMapper;

  constructor() {
    this.profileMapper = new ProfileMapper();
    this.initializeConnector();
  }

  initializeConnector(): void {
    this.repository = axios.create({
      baseURL,
    });

    this.repository.interceptors.request.use(
      async (config) => {
        const firebaseApp = getApp();
        const token = await getAuth(firebaseApp).currentUser?.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  async getEntertainmentProfiles({
    query,
    filters,
    page,
    limit,
    locales,
    profileTypes,
  }: {
    query: string;
    filters: any;
    page: number;
    limit: number;
    locales: string[];
    profileTypes: string[];
  }): Promise<Profile[]> {
    const flattenFilters = flattenArrayLikeKeys(filters);
    if (typeof flattenFilters?.profileType === "string") {
      profileTypes = [flattenFilters.profileType];
    }

    const response = await this.repository.post(`/profile/poke/entertainment-profiles`, {
      filters: flattenFilters,
      locales: locales,
      profileTypes: profileTypes,
      page: page,
      limit: limit,
      query: query,
    });

    const profiles = response?.data?.profiles;

    if (!profiles) {
      throw new Error("No profile found with the provided filters.");
    }

    return profiles.map((profile: any) => {
      return this.profileMapper.map(profile);
    });
  }

  async getEntertainmentProfilesCount(filters: any): Promise<number> {
    // TODO: remove mocking:
    return 0;
  }

  async getEntertainmentProfile(
    entertainmentProfileId: string | number,
    entertainmentProfileUuid: string,
  ): Promise<Profile> {
    const response = await this.repository.get(`/profile/${entertainmentProfileUuid}`);

    const profile = response?.data?.data;

    if (!profile) {
      throw new Error("No entertainment profile found with the provided id!");
    }

    return this.profileMapper.map(profile);
  }

  async getGeoFreeEntertainmentProfileLocation(
    entertainmentProfileId: string,
    countryCode: string,
    customerLat: string,
    customerLng: string,
  ): Promise<GeoFreeLocationApiResponse> {
    // TODO: remove mocking:
    return undefined;
  }

  async getPokeEntertainmentProfile(
    entertainmentProfileId: string,
    domain: string,
    entertainmentProfileUuid: string,
  ): Promise<Profile> {
    const response = await this.repository.get(`/profile/${entertainmentProfileUuid}`);

    const profile = response?.data?.data;

    if (!profile) {
      throw new Error("No entertainment profile found with the provided id!");
    }

    return this.profileMapper.map(profile);
  }

  async getCustomerProfile({ customerUuid }: { customerProfileId: string; customerUuid: string }): Promise<Profile> {
    const response = await this.repository.get(`/profile/${customerUuid}`);
    const profile = response?.data?.data;

    if (!profile) {
      throw new Error("No customer profile found with the provided id!");
    }

    return this.profileMapper.map(profile);
  }

  async getCustomerProfilesCount({
    domainNames,
    filters,
    profileId,
    profileUuid,
  }: {
    domainNames: string[];
    filters: Record<string, any>;
    profileId: string;
    profileUuid: string;
  }): Promise<Record<string, number> | number> {
    const response = await this.repository.get(`/profile/poke/customers/count`, {
      params: { sites: domainNames, filters, entertainment_profile_uuid: profileUuid },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    });
    return response.data;
  }

  async getAttachments(profile: Profile): Promise<Attachment[]> {
    // keep getAttachments as "await" to satisfy the ProfileConnector type
    return (profile.media || [])?.map((mediaItem) => {
      return {
        cdnHash: extractAttachmentCdnHash(mediaItem.url),
        displayUrl: mediaItem.url,
      };
    });
  }

  async getCreditsBalance(profile: Profile, domainName: string): Promise<number> {
    const creditsResponse = await this.repository.get(`/profile/${profile.uuid}/credits/balance`, {
      headers: {
        "x-domain": domainName,
      },
    });
    return +creditsResponse.data.data;
  }

  supportMultiDomainCount(): boolean {
    return false;
  }

  async searchCustomerProfilesByName(filters: any): Promise<TargetAutoCompleteItem[]> {
    // TODO: remove mocking:
    return [];
  }
}
