import { defineStore } from "pinia";
import { QueueCount, StatisticsState, QueueCategoryEnum, ChatDocument, MessageDocument } from "@/types";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { splitTextWithUnderline } from "@/helpers/utility";

export const useStatisticsStore = defineStore("statistics", {
  state: (): StatisticsState => ({
    messages: [],
    allChatDocuments: [],
    queueCounts: [],
    activeChat: null,
    chatMessages: [],
    nextChatDocPage: null,
    previousChatDocPage: null,
    nextPokeDocPage: null,
    previousPokeDocPage: null,
    pokes: [],
    queueCountInterval: null,
    isLoadingAllChatDocuments: false,
  }),

  getters: {
    reversedChatMessages: (state: StatisticsState) => {
      return [...state.chatMessages].reverse();
    },
    computedQueueCounts: (state: StatisticsState) => {
      const userGeos = useAuthenticatorStore().user.geos || [];

      const baseQueues: QueueCount[] = userGeos.map((geo: string) => {
        const [languageCode, countryCode] = splitTextWithUnderline(geo);
        return {
          locale: geo,
          countryCode,
          languageCode,
          counts: {},
        };
      });
    
      const queueMap = new Map<string, QueueCount>();
      baseQueues.forEach((queueEntry) => queueMap.set(queueEntry.locale, queueEntry));
    
      state.allChatDocuments.forEach((chat: ChatDocument) => {
        const { locale, lastMessageMessagePool, lastMessage } = chat;
        const formattedPool = (lastMessageMessagePool ?? QueueCategoryEnum.NSFW).replace(" ", "_").toUpperCase();
        const messageCreatedAt = lastMessage?.createdAt;
    
        const existingQueue = queueMap.get(locale);
    
        if (existingQueue) {
          // Initialize the pool if not already present
          if (!existingQueue.counts[formattedPool]) {
            existingQueue.counts[formattedPool] = { count: 0, oldestMessage: null };
          }
    
          // Update the count
          existingQueue.counts[formattedPool].count += 1;
    
          // Update the oldestMessage
          const currentOldest = existingQueue.counts[formattedPool].oldestMessage;
          if (!currentOldest || (messageCreatedAt && messageCreatedAt < currentOldest)) {
            existingQueue.counts[formattedPool].oldestMessage = messageCreatedAt;
          }
        } else {
          const [languageCode, countryCode] = splitTextWithUnderline(locale);
          const newQueue: QueueCount = {
            locale,
            languageCode,
            countryCode,
            counts: {
              [formattedPool]: { count: 1, oldestMessage: messageCreatedAt },
            },
          };
          queueMap.set(locale, newQueue);
        }
      });
    
      return Array.from(queueMap.values());
    },
  },

  actions: {
    pushChatMessage(message: MessageDocument) {
      if (!this.chatMessages.find((chatMessage) => chatMessage.id === message.id)) {
        this.chatMessages.push(message);
      }
    },
    updateQueueCount(unclaimedChat: ChatDocument, type: "added" | "removed" | "modified"): void {
      const existingChatIndex = this.allChatDocuments.findIndex(
          (existingChat: ChatDocument) => existingChat.id === unclaimedChat.id,
      );
  
      if (type === "removed" && existingChatIndex !== -1) {
          this.allChatDocuments.splice(existingChatIndex, 1);
      } else if (type === "added" && existingChatIndex === -1) {
          this.allChatDocuments.push(unclaimedChat);
      } else if (type === "modified" && existingChatIndex !== -1) {
          this.allChatDocuments[existingChatIndex] = unclaimedChat;
      } else if (type === "modified" && existingChatIndex === -1) {
          this.allChatDocuments.push(unclaimedChat);
      }
  
      this.allChatDocuments = [...this.allChatDocuments];
    },
    /**
     * Initiates an interval to update `queueCounts` from `computedQueueCounts` at a given frequency.
     *
     * Call this once after the store is initialized (e.g., in a component's mounted hook).
     * Performs an immediate update, then continues updating at the specified interval.
     *
     * @param {number} intervalInSeconds - The update interval, expressed in seconds. Defaults to 10.
     */
    startQueueUpdateInterval(intervalInSeconds = 10) {
      this.queueCountInterval = setInterval(() => {
        this.queueCounts = this.computedQueueCounts;
      }, intervalInSeconds * 1000);

      // Wait for snasphot to load
      setTimeout(() => {
        this.queueCounts = this.computedQueueCounts;
      }, 3000);
    },
    clearQueueUpdateInterval() {
      clearInterval(this.queueCountInterval);
    },
  },
  
});