import { Dayjs } from "dayjs";
import { FirebaseApp } from "firebase/app";
import { Timestamp } from "firebase/firestore";
import { logsCategories } from "@/types/categories";
import { OriginType, PokeStrategyName, PokeTypeName, MessageTypeName } from "@/types/constants";

export const isPromiseFulfilledResult = <T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
  p.status === "fulfilled";
export const isPromiseRejectedResult = <T>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
  p.status === "rejected";

export type LoginCredentials = {
  email: string;
  password: string;
};

export type FormDefinition = {
  valid: boolean;
  fields: object;
  rules?: {
    [key: string]: object;
  };
};

export type LoginForm = FormDefinition & {
  fields: LoginCredentials;
  rules: {
    email: ((message?: string) => Record<string, unknown>)[];
    password: ((message?: string) => Record<string, unknown>)[];
  };
};

export type ResetPasswordForm = FormDefinition & {
  fields: {
    email: string;
  };
  rules: {
    email: ((message?: string) => Record<string, unknown>)[];
  };
};

export type Customer = {
  uuid?: string;
  uid: string | number;
  name: string;
};

export type Gender = {
  icon: string;
  color: string;
};

export type CivilStatus = {
  icon: string;
  title: string;
};

export type Profile = {
  about_me: string;
  about_you: string;
  adult_level: string;
  age: number;
  appearance: string;
  avatar: string;
  base_profile_id: number;
  build: string;
  category: string;
  civil_status: string;
  company_id: string;
  created_at?: string;
  creditsBalance: number;
  date_of_birth: string;
  domain: string;
  education?: string;
  eye_colour: string;
  foreignProfileId: number;
  gender: string;
  geo: any[];
  geo_free: number;
  geo_iso: string;
  geo_language: string;
  geo_latitude: string;
  geo_locality?: string;
  geo_longitude: string;
  geo_type: string;
  hair_colour: string;
  has_photo: boolean;
  has_piercing: boolean;
  has_tattoo: boolean;
  hobbies: string;
  housing_situation?: string;
  id: number | string;
  is_active: boolean;
  is_angel: boolean;
  is_disabled: boolean;
  is_entertainment: boolean;
  is_smoker: boolean;
  last_activity?: string;
  last_modified_user_email: string;
  length: string;
  sexual_orientation?: string;
  media: {
    id: string;
    url: string;
  }[];
  metadata: ProfileMetadata;
  name: string;
  origin: OriginType;
  passion?: string;
  photo_count: number;
  photo_setting: string;
  pictures?: {
    hash: string;
    extension: string;
    type: string;
    url?: string;
  }[];
  profession?: string;
  profileCmsId?: any;
  radius?: any; // TODO: can we remove it?
  real_name: string;
  search_preference: string;
  sexualTags?: string;
  status: string;
  transportation?: string;
  uid?: string;
  updated_at: string;
  uuid?: Branded<string, "SenderUuid">;
  profile_type?: string;
};

export type BirthdateObject = {
  date: string;
  timezone: string;
  timezone_type: number;
};

export type LegacyProfile = {
  aboutMe: string;
  aboutYou: string;
  adultLevel: string;
  age: string;
  approved: string;
  bcProfileVersion: string;
  birthdate: string | BirthdateObject;
  build: string;
  category?: any;
  city?: any;
  civilStatus: string;
  confirmed: string;
  country: string;
  created: string;
  creditsBalance: number;
  creditsPurchased: number;
  deleted?: any;
  deleteReason?: any;
  deleteType?: any;
  detailAboutmeLength: number;
  detailAboutyouLength: number;
  disabled?: any;
  domainId: number;
  education?: any;
  email?: any;
  eroticLevel: string;
  eyeColor: string;
  fake: number;
  favoritesSent: number;
  foreignProfileId: number;
  gender: string;
  genericTags?: any;
  geoFree: number;
  geoIso: string;
  geoLanguage: string;
  geoLatitude?: any;
  geoLocality: string;
  geoLongitude?: any;
  geoPostcode: string;
  geoRegion1: string;
  geoRegion2: string;
  geoRegion3: string;
  geoRegion4: string;
  geoTimezone: string;
  habitat: string;
  hairColor: string;
  hasPhoto: string;
  hobbies?: any;
  id: number | string;
  inactiveImport: string;
  ip?: any;
  isErotic: string;
  isModified: string;
  isSnowflake: string;
  lastActivity: string;
  lastPayment?: any;
  length?: string;
  lengthChoice: string;
  login: string;
  mailable: number;
  matchgameVisited: string;
  mobileNotifications: string;
  modified: string;
  msn: number;
  name: string;
  p: string;
  pageViews: string;
  passwHashed: string;
  pe?: any;
  photo: string;
  pi: string;
  piercing: string;
  premiumEndDate?: any;
  profession: string;
  profileCmsId: string;
  province?: any;
  rating?: any;
  real_name: string;
  redirected: string;
  redirectProfile?: any;
  registrationPlatform: string;
  remindCount: string;
  reminded?: any;
  revalidateNotes?: any;
  sexualTags?: any;
  showFreeCredit: string;
  signupData?: any;
  site: string;
  smoke: string;
  status: string;
  support: number;
  suspicious: string;
  tags: string;
  tattoo: string;
  title: string;
  transportation: string;
  typesAllowed?: any;
  uuid?: Branded<string, "SenderUuid">;
  profileType?: string;
};

export type ProfileMetadata = {
  length: number;
  passion: string;
  profession: string;
  transportation: string;
  housing_situation: string;
  sexual_orientation: string;
  initial_duplicated_geo_id?: number[] | null;
};

export type SenderType = "customer" | "entertainmentProfile" | "agent" | "queueableTrigger";

export type ChatDocument = {
  id: Branded<string, "ChatId">;
  customer: Customer;
  profile: Pick<Profile, "uuid" | "id" | "name">;
  isConversation: boolean;
  isDeleted: boolean;
  lastSenderType: SenderType;
  createdAt: Timestamp;
  updatedAt: Timestamp | null;
  lastMessage: MessageDocument;
  lastMessageMessagePool: string;
  claimedBy: string;
  claimExpiresAt: Timestamp;
  locale?: string;
  domain: string;
  origin?: Origin;
  trigger?: {
    type: string;
  };
  attachments?: UsedAttachment[];
};

export interface UsedAttachment {
  id: number | null;
  url: string;
  type: SenderType;
  usedAt: Timestamp;
}

type CommonQueryFilters = {
  locale: string;
  domains?: string[];
  startAt?: string;
  endAt?: string;
};

export type ChatQueryFilters = CommonQueryFilters & {
  customerName: string;
  chatId: Branded<string, "ChatId">;
  playerName: string;
  operatorName: string;
};

export type ProfileConnectorName = "messageCentral" | "profileApi";

export type Origin = {
  id?: string;
  type: string;
  attachmentId?: number;
};

export type PokeOriginId = {
  id: string;
  domain: string;
  poke_id?: string;
};

export type PokeOrigin = {
  ids?: PokeOriginId[];
  type: string;
};

export type MessageParams = {
  content: string;
  type: string;
  attachment: string | null;
  messageLength: number;
  origin?: Origin;
  triggerType?: string;
  senderUuid?: Branded<string, "SenderUuid">;
  recipientUuid?: string;
};

export type MessageMetadata = {
  customer: {
    lastActivity?: string;
  };
};

export type MessageDocument = {
  attachment: Attachment | string | null;
  attachmentFilenameForLegacy?: string | null;
  content: string;
  createdAt: Timestamp;
  creditsSpent?: number;
  deletedAt: Timestamp | null;
  hasAttachment?: boolean;
  id: Branded<string, "MessageId">;
  imported?: boolean;
  isFree?: boolean;
  isRead?: boolean;
  locale?: string;
  maskedContent?: string;
  messageLength?: number;
  metadata?: any;
  modifiedAttachment?: string | null;
  modifiedBy?: string | null;
  modifiedContent?: string | null;
  operatorResponseTime?: number;
  origin?: Origin;
  previousMessage?: MessageDocument;
  recipientUuid?: string;
  sender?: string;
  senderId: Branded<string, "SenderId">;
  senderName?: string;
  senderType: string;
  senderUuid?: Branded<string, "SenderUuid">;
  tenant?: string;
  trigger?: any;
  type: MessageTypeName;
  typeId?: any;
};

export type UpdateMessageEntity = {
  chatId: Branded<string, "ChatId">;
  messageId: Branded<string, "MessageId">;
  locale: string;
  content: string;
  modifiedContent: string;
  attachment: string;
  modifiedAttachment: string;
  abuseModificationData: string;
  modifiedAt: string;
  startAt: string;
  endAt: string;
};

export type DeleteMessageEntity = {
  chatId: Branded<string, "ChatId">;
  messageId: Branded<string, "MessageId">;
  locale: string;
};

export type PokeDocument = {
  id: Branded<string, "PokeId">;
  type: PokeTypeName;
  scheduled: Timestamp;
  filters: any;
  profile: any;
  agent: {
    id: string;
    name: string;
  };
  company: string | number;
  message: MessageDocument;
  createdAt: Timestamp;
  deletedAt?: Timestamp | null;
  targets: {
    targetsPerDomain: {
      site: string;
      status: string | null;
      estimate: number;
      reached: number;
    }[];
    estimate: number;
    reached: number;
  };
  domain?: string;
  domains: string[];
  origin?: PokeOrigin;
  strategy?: PokeStrategyName;
  locale: string;
  followUp?: {
    parentId: Branded<string, "PokeId">;
    previousId?: Branded<string, "PokeId">;
    index?: number;
  };
  recurring?: {
    mainPoke: string;
    previousPoke: string;
    iteration: number;
  };
  frequency?: {
    day: number;
    limit?: number;
  };
  abuse?: {
    matches: AbuseMatch[];
    status: "safe" | "abuse" | "pending" | "expired";
    claimedBy?: string;
    claimedAt?: Timestamp;
    checkedBy?: string;
    checkedAt?: Timestamp;
  };
  tenant: string;
  updatedAt?: Timestamp;
  updatedBy?: {
    id: string;
    name: string;
  };
};

type PokeStatisticsBase = {
  estimate: number;
  reached: number;
  read: number;
};

export type PokeStatisticsTotals = PokeStatisticsBase & {
  freeReplies: number;
  paidReplies: number;
};

export type EnrichedPokeStatPerRun = PokeStatisticsTotals & {
  index: number;
  id: Branded<string, "PokeId">;
  scheduledAt: Date;
  score: number;
};

export type PokeStatisticsDomain = PokeStatisticsBase & {
  name: string;
  poke_id?: string;
  origin_poke_id?: any;
  reached_bq: number;
  replies_paid: number;
  replies_free: number;
  status?: string;
};

export type PokeStatistic = {
  poke_id: Branded<string, "PokeId">;
  agent: {
    agent_id: string;
    agent_name: string;
  };
  created_at: string;
  origin: Origin;
  domain_list: string;
  filters: string;
  locale: string;
  message: {
    attachment?: any;
    attachmentFilename?: any;
    content: string;
    messageLength: string;
    type: string;
    sub_type?: PokeTypeName;
  };
  profile: Profile;
  scheduled_at: string;
  totals: PokeStatisticsTotals;
  company: {
    id: number;
    name: string;
  };
  domains: PokeStatisticsDomain[];
  strategy: {
    name: string;
    days: string;
    index?: string;
    main_id?: string;
    previous_id?: string;
  };
  updatedBy?: {
    id: string;
    name: string;
  };
};

export type EnrichedPokeStatistic = PokeStatistic & {
  score: number;
  extendedStatus: any;
  pokeTypeName: string;
  pokeType: {
    value: string | null;
    title: string;
    icon: string;
    color: string;
  };
};

export type DailyPokeFilters = {
  date: string;
  operatorId?: string;
  operatorName: string;
  pokeStrategy: string;
  attachment: string;
  paymentBehavior: string;
};

export type PokeTypeDisplay = { title: string; icon: string; color: string; cssTextColor: string };

export type Log = {
  id: Branded<string, "LogId">;
  locale: string;
  content: {
    group: string;
    groupValue: string;
    value: string;
  };
  agentId: string;
  concerning: ConcerningType;
  category: LogCategoryName;
  createdAt: Timestamp;
  createdBy?: string;
  deletedAt?: Timestamp | null;
};

export enum NotificationTypes {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
}

export type Notification = {
  message: string;
  show: boolean;
  index?: number;
  type?: NotificationTypes;
  timeout?: string | null;
};

export type UserData = {
  uid?: string;
  displayName?: string;
  email?: string;
  photoUrl?: string;
  claims?: any;
  roles?: string[];
  geos?: string[];
  companyId?: number;
};

export type Announcement = {
  id: Branded<string, "AnnouncementId">;
  locale?: string;
  topicId?: string;
  message: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
};

export type AnnouncementsTopic = {
  id?: Branded<string, "AnnouncementsTopicId">;
  locale: string;
  title: string;
  emoji: string;
};

export type AnnouncementsState = {
  isAnnouncementsLoadingDone: boolean;
  announcementsPerLocale: Record<string, Record<string, Record<string, Announcement>>>;
  announcementsTopics: Record<string, Record<string, AnnouncementsTopic>>;
  isAnnouncementDialogShown: boolean;
};

export type SystemState = {
  currentSystemNotification: SystemNotification;
  systemNotifications: SystemNotification[];
};

export type FirebaseProjectConfig = {
  apiKey: string;
  projectId: string;
  messagingSenderId: string;
  appId: string;
  vapidKey: string;
};

export type RegionalProjects = {
  [key: string]: FirebaseProjectConfig;

  eu: FirebaseProjectConfig;
  us: FirebaseProjectConfig;
};

export type ConfigState = {
  variables: {
    central: FirebaseProjectConfig;
    profileBaseUrl: string;
    regional: RegionalProjects;
  };
  initialized: boolean;
  domains: DomainConfiguration[];
  project: FirebaseApp;
  pokeConfig: any;
  appearance: {
    title: string;
    logo: string;
  };
};

export type GeoState = {
  regions: Region[];
  cities: City[];
  levels: number;
};

export type Operator = {
  available: string;
  geos: string;
  id: string;
  online?: string;
  score: string;
  messagePool?: string;
};

export enum QueueCategoryEnum {
  NSFW = "NSFW",
  SFW = "SFW",
  AGENT_ONLY = "AGENT_ONLY",
}

export type Category = {
  operators: Operator[];
};

export type OperatorsCategories = {
  operators: OperatorsCategoriesDetailed;
};

export type OperatorsCategoriesDetailed = {
  SFW?: Category;
  NSFW?: Category;
  AGENT_ONLY?: Category;
}

export type DebugState = {
  loading: boolean;
  operators: Operator[];
  connectedOperators: OperatorsCategories;
  operatorPools: any;
};

export type OperatorsPoolCategory = {
  id: string;
  label: string;
  description: string;
}

export type DomainConfiguration = {
  domain: string;
  name: string;
  locale: string;
  geoStrategy: string;
  origin?: string;
  messageCentralUrl?: string;
  apiKey?: string;
  authDomain?: string;
  projectId?: string;
  storageBucket?: string;
  geoStrategyRegionField?: string;
  geoStrategyCityField?: string;
  minCharactersOperatorResponse?: number;
  geoWizardEnabled: boolean;
  suggestionsEnabled?: boolean;
  cdnBaseLiveUrl?: string;
  profileApiEnabled?: boolean;
  profileTypes?: string[];
};

export type AuthState = {
  isUserInitialized: boolean;
  token: string;
  user: UserData;
  forceMfa: boolean;
  hasMfaEnabled: boolean;
  password: {
    hasReset: boolean;
    requestedAt: Date;
  };
  mfaVerificationId: string;
  tenant: string;
};

export type ConcerningType = "customer" | "entertainmentProfile";

export type LogCategory = {
  title: string;
  icon: string;
  color: string;
  cssColor: string;
  groups?: RadioItem[];
  predefinedValues?: object[];
};

export type RadioItem = {
  label: string;
  value: string;
};

export type LogPredefinedValues = {
  name: string;
  title: string;
  values: string[];
};

export type SuggestionDocument = {
  id: Branded<string, "SuggestionId">;
  createdAt: Date;
  suggestion: string;
  wantedImage: boolean;
};

export type LogCategoryName = keyof typeof logsCategories;
export type LogsByCategory = Record<LogCategoryName, Log[]>;
export type CategorizedLogs = Record<ConcerningType, LogsByCategory>;

export type ChatState = {
  claimedChat: ChatDocument;
  unclaimedChats: ChatDocument[];
  chats: ChatDocument[];
  currentMessages: MessageDocument[];
  categorizedLogs: CategorizedLogs;
  waitingForClaim: boolean;
  initialized: boolean;
  hasBeginningMessage: boolean;
  isLoadingUnclaimedChats: boolean;
  suggestions: SuggestionDocument[];
};

export type DomainCount = {
  domainName: string;
  count: number;
};

export type StatisticsState = {
  messages: MessageDocument[];
  activeChat: ChatDocument;
  chatMessages: MessageDocument[];
  nextChatDocPage: any | null;
  previousChatDocPage: any | null;
  nextPokeDocPage: any | null;
  previousPokeDocPage: any | null;
  queueCounts: QueueCount[];
  allChatDocuments: ChatDocument[];
  queueCountInterval: null | ReturnType<typeof setInterval>;
  isLoadingAllChatDocuments: boolean;
  pokes: any[]; // TODO: Add Poke interface
};

export type QueueCount = {
  locale: string;
  countryCode: string;
  languageCode: string;
  counts: QueueCounts;
};

type QueueCounts = {
  [poolName: string]: {
    count: number;
    oldestMessage: Timestamp | null;
  };
};

export type QueueCountPools = {
  locale: string;
  countryCode: string;
  languageCode: string;
  countSFW: number;
  countNSFW: number;
  countAgentOnly: number;
};

export type SocketState = {
  isConnected: boolean;
};

export type SettingsState = {
  isClaimedChatNotificationEnabled: boolean;
  claimedChatNotificationTimeoutSeconds: number;
};

export type ScheduleProfileInterface = {
  future: {
    [key: string]: PokeDocument;
  };
  past: {
    [key: string]: PokeDocument;
  };
};

export type AbuseWord = {
  id?: Branded<string, "AbuseWordId">;
  value: string;
  locale: string;
  category: string;
  createdAt?: Timestamp;
  createdBy: {
    uid: string;
    displayName: string;
    companyId: number;
  };
};

export type AbuseState = {
  initialized: boolean;
  words: any[];
  recipients: { [geo: string]: any[] };
  abuseMessages: AbuseMessage[];
  abusePokes: PokeDocument[];
  claimedAbuseMessage: AbuseMessage;
  claimedAbusePoke: PokeDocument;
  abuseHistoryMessages: AbuseMessage[];
  firstAbuseHistoryMessage: MessageDocumentWithAbuse | null;
  lastAbuseHistoryMessage: MessageDocumentWithAbuse | null;
};

export type ReportCategory = {
  icon: string;
  text: string;
  value: string;
};

export type ReportedMessagesStoreState = {
  reportedMessages: ReportedMessage[];
  currentReportedMessage: ReportedMessage | null;
  firstReportedMessageId: string | null;
  lastReportedMessageId: string | null;
  categories: Record<string, ReportCategory[]>;
};

export type ReportedMessage = {
  id: Branded<string, "ReportedMessage">;
  locale: string;
  chatId: Branded<string, "ChatId">;
  message: MessageDocument;
  report: {
    reportedAt: Timestamp;
    reportedBy: string;
    comment: string;
    category: string;
    processed: boolean;
  };
};

export type MessageDocumentWithAbuse = MessageDocument & {
  abuse: {
    matches: AbuseMatch[];
    status: "safe" | "abuse" | "pending" | "expired";
    claimedBy?: string;
    claimedAt?: Timestamp;
    checkedBy?: string;
    checkedAt?: Timestamp;
  };
};

export type AbuseMessage = {
  message?: MessageDocumentWithAbuse;
  chat?: ChatDocument;
  poke?: PokeDocument;
};
export type AbuseMessageWithAttachmentToggle = AbuseMessage & {
  showAttachment: boolean;
};

export type AbuseListItem = {
  type: string;
  customer: {
    id: number | string;
    name: string;
  };
  profile: {
    id: number | string;
    name: string;
  };
  agent: {
    id: string;
    name: string;
  };
  id: string;
  message: {
    content: string;
    attachment: Attachment | string;
  };
  createdAt: Timestamp;
  abuseMessage?: AbuseMessage;
  abusePoke?: PokeDocument;
};

export type AbuseListItemWithAttachmentToggle = AbuseListItem & {
  showAttachment: boolean;
};

export type DirectionStepName = "next" | "previous";

type BasicFilter = {
  direction?: DirectionStepName;
  sortBy?: string;
  sortDesc?: boolean;
  category?: string;
};

export type ReportedMessagesFilter = BasicFilter;

export type AbuseHistoryFilter = {
  chatId: Branded<string, "ChatId">;
  operatorName: string;
  direction?: DirectionStepName;
  sortBy?: string;
  sortDesc?: boolean;
  operatorId?: string;
};

export type Likelihood =
  | "VERY_LIKELY"
  | "LIKELY"
  | "POSSIBLE"
  | "UNLIKELY"
  | "VERY_UNLIKELY"
  | "LIKELIHOOD_UNSPECIFIED";

export type AbuseMatch = {
  type: string;
  quote: string;
  likelihood: Likelihood;
};

export type AbuseMessageParsedItem = {
  type: "match" | "text";
  original: string;
  displayText: string;
  likelihood?: Likelihood;
  matchType?: string;
};

export type Attachment = {
  id?: number;
  url?: string;
  cdnHash?: string;
  displayUrl: string;
  type?: string;
  level?: number;
  isAvailable?: boolean;
  usedAt?: Timestamp;
  tags?: string[];
};

export type LegacyAttachment = {
  id: number;
  adultLevel: string;
  path: string;
  filename: string;
  mimeType: string;
};

export type AttachmentsState = {
  attachments: Attachment[];
};

export type GeoDocRefOptions = {
  userUID?: string;
  dateRange?: {
    start: Date;
    end: Date;
  };
};

export type PokeCalendarInterface = {
  name: string;
  start: any;
  end: Date;
  timed: boolean;
  color: string;
  details: {
    profileId: number;
    profileUuid?: string;
    profileName: string;
    createdAt: string;
    messageLength: number;
    messageContent: string;
    scheduledAtProfileTime: string;
    scheduledAtYourTime: string;
    targetsEstimate: number;
    agentName: string;
    agentId: string;
    company: string;
    domains: string[];
    strategy: PokeStrategyName;
  };
  poke: any;
};

export type PokeMessage = {
  type: string;
  content: string;
  messageLength: number;
  attachment: Attachment | null;
  hasAttachment: boolean; // used for Media Gallery feature in FlirtMachine
  attachmentFilenameForLegacy?: string;
  scheduledDateTime: Dayjs;
};

export type PokeMessageVariable = {
  varName: string;
  usedCount: number;
};

export type PokeMessageParsedItem = {
  type: "var" | "text";
  original: string;
  fixedVarName?: string;
  isBroken?: boolean; // has spaces
  isValid?: boolean;
  hasSentenceBegin?: boolean;
};

export type FollowUpPoke = {
  pokeId?: Branded<string, "PokeId">;
  poke: PokeMessage;
  order: number;
  isOpen: boolean;
  isValid: boolean;
  key: number;
  minDateTime: Dayjs;
  titleDateTime: string;
  originalDateTime?: Dayjs;
};

export type IconMapping = {
  eye_colour: string;
  hair_colour: string;
  is_smoker: string;
  has_tattoo: string;
  has_piercings: string;
  age: string;
  date_of_birth: string;
  civil_status: string;
  housing_situation: string;
  sexual_orientation: string;
  transportation: string;
  location: string;
  location_origin: string;
  passion: string;
  profession: string;
  name: string;
  real_name: string;
  hobbies: string;
  about_me: string;
  about_you: string;
  adult_level: string;
  appearance: string;
  build: string;
  length: string;
  status: string;
};

export type RegionFilter = {
  iso: string;
  language: string;
  region1?: string;
  region2?: string;
  region3?: string;
  region4?: string;
  locality?: string;
  strategy?: string;
  level?: string;
};

export type Region = {
  label: string;
  filter: RegionFilter;
};

export type City = {
  label: string;
  place: RegionFilter;
};

export type PokeProfile = {
  id: number;
  name: string;
  timezone: string;
  profileType?: string;
};

export type LegacyPokeOnlyBooleanFilters = {
  in_conversation: boolean;
  not_in_conversation: boolean;
  with_photo: boolean;
  without_photo: boolean;
  with_text: boolean;
  without_text: boolean;
  age_18_till_25: boolean;
  age_26_till_35: boolean;
  age_36_till_45: boolean;
  age_46_till_54: boolean;
  age_55_till_99: boolean;
  age_unknown: boolean;
  has_credits: boolean;
  had_credits: boolean;
  never_had_credits: boolean;
  past_paid_within_1_week: boolean;
  past_paid_within_2_weeks: boolean;
  past_paid_within_1_month: boolean;
  past_paid_older_then_1_month: boolean;
  gender_male: boolean;
  gender_female: boolean;
  gender_couple: boolean;
  gender_transgender: boolean;
  gender_unknown: boolean;
};

export type LegacyPokeFilters = LegacyPokeOnlyBooleanFilters & {
  duplicate_ip_target_level: string;
  geoRegion1: RegionFilter | string;
  limit: number;
  lastLogin: number;
  lastLoginByHours: number;
  selectedTargetsPerDomain: SelectedTargetsPerDomain[];
  targetFilterType: string;
};

export interface PokePayload {
  agent: {
    id: string;
    name: string;
  };
  company: number;
  createdAt: Timestamp;
  domains: string[];
  filters: any;
  id: string | null;
  frequency?: {
    day: number;
    limit?: number;
  };
  followUp?: {
    index?: number;
    parentId: Branded<string, "PokeId">;
    previousId?: Branded<string, "PokeId">;
  };
  locale?: string;
  message: PokeMessage;
  origin?: PokeOrigin;
  profile: PokeProfile;
  profileCmsId?: string;
  scheduled: Timestamp;
  strategy?: PokeStrategyName;
  targets: PokeTargets;
  tenant: string;
  type: string;
  updatedAt?: Timestamp;
  updatedBy?: {
    id: string;
    name: string;
  } | null;
}

export type PokeTargets = {
  estimate: number;
  reached?: number;
  targetsPerDomain?: {
    site: string;
    estimate: number;
    reached: number;
  }[];
};

export type LegacyPokePayload = Iterable & {
  type: string;
  targetCount: number;
  userId: string;
  content: string;
  origin: OriginType;
  strategy?: PokeStrategyName;
  scheduled: string;
  mediaId?: string;
  settings?: string[];
  attachment?: {
    filename?: string;
  };
  filters: any;
  profileId?: number;
  profileCmsId: string;
  originId?: string;
};

export type NonRealtimeRepository = {
  dispatch(action: any, params: any): void;
};

export type Repository = NonRealtimeRepository & {
  enableListeners(options?: Record<string, unknown>): void;

  disableAllListeners(): void;
};

export interface NoLocaleSelectOption {
  locale: string | null;
  flagCountryCode: string | null;
  countryName: string;
}

export type CountrySelectOption = {
  locale: string;
  countryCode: string;
  flagCountryCode: string;
  countryName: string;
  languageCode: string;
  languageName: string;
};

export type RegionSelectOption = {
  value: string;
  countryCode: string;
  name: string;
};

export type DatePeriodInformation = {
  start: string;
  end: string;
};

export type Iterable = {
  [key: string]: any;
};

export type IterableStrings = {
  [key: string]: string;
};

export type OperatorScoreStatistic = {
  operator_id: string;
  operator_name: string;
  locale: string;
  score: number;
};

export type OperatorScoreEnrichedStatistic = {
  id: string;
  name: string;
  locale: string;
  languageCode: string;
  countryCode: string;
  score: number;
};

export type PokeDomainSelectOption = {
  text: string;
  value: DomainConfiguration;
};

export type PokeWizardStep = {
  name: string;
  label?: string;
  allowManualSwitch: boolean;
};

export type GeoFreeLocationApiResponse = {
  profile: string;
  location: GeoFreeLocation;
};

export type GeoFreeLocation = {
  name: string;
  region: string;
  latitude: number;
  longitude: number;
};

export type QueryStringObject = {
  [key: string]: string | number;
};

export type GeoFreeLocationApiParams = {
  entertainmentProfileId: string;
  domain: string;
  countryCode: string;
  customerLat: string;
  customerLng: string;
};

export type ProfileConnector = {
  getEntertainmentProfiles({
    query,
    filters,
    page,
    limit,
    locales,
  }: {
    query: string;
    filters: any;
    page: number;
    limit: number;
    locales?: string[];
  }): Promise<Profile[]>;

  getEntertainmentProfilesCount(filters: any): Promise<number>;

  getEntertainmentProfile(entertainmentProfileId: string | number, entertainmentProfileUuid: string): Promise<Profile>;

  getGeoFreeEntertainmentProfileLocation(
    entertainmentProfileId: string,
    countryCode: string,
    customerLat: string,
    customerLng: string,
  ): Promise<GeoFreeLocationApiResponse>;

  getPokeEntertainmentProfile(
    entertainmentProfileId: string,
    domain: string,
    entertainmentProfileUuid: string,
  ): Promise<Profile>;

  getCustomerProfile({
    customerProfileId,
    customerUuid,
  }: {
    customerProfileId: string;
    customerUuid: string;
  }): Promise<Profile>;

  getCustomerProfilesCount(params: {
    domainNames: string[];
    filters: Record<string, any>;
    profileId: string;
  }): Promise<Record<string, number> | number>;

  getAttachments(profile: Profile): Promise<any>;

  getCreditsBalance(profile: Profile, domainName?: string): Promise<number>;

  supportMultiDomainCount(): boolean;

  searchCustomerProfilesByName(filters: any): Promise<TargetAutoCompleteItem[]>;
};

export type MessageStatisticsPeriodData = {
  sent_to_free: number;
  sent_to_paid: number;
  sent_total: number;
  received: number;
  hours?: MessageStatisticsPeriodData[];
};

export type MessageStatisticItem = {
  company_id: number;
  company_name: string;
  sender_id: string;
  sender_name: string;
  locale: string;
  dates: MessageStatisticsPeriodData[];
};

export type EnrichedMessageStatisticItem = MessageStatisticItem & Omit<MessageStatisticsPeriodData, "hours">;

export enum Roles {
  Operator = "CHAT_OPERATOR",
  Poker = "CHAT_POKER",
  Manager = "CHAT_MANAGER",
  Admin = "CHAT_ADMIN",
  AbuseChecker = "CHAT_ABUSE_CHECKER",
  BetaAccess = "CHAT_BETA_ACCESS",
  Developer = "DEBUG_DEVELOPER",
  ChatSuggestions = "CHAT_SUGGESTIONS",
  OperatorSFW = "CHAT_OPERATOR:SFW",
  OperatorNSFW = "CHAT_OPERATOR:NSFW",
  OperatorAgentOnly = "CHAT_OPERATOR:AGENT_ONLY",
}

export enum PokeFilterAges {
  Age18_25 = "18-25",
  Age26_35 = "26-35",
  Age36_45 = "36-45",
  Age46_54 = "46-54",
  Age55_99 = "55-99",
  Unknown = "unknown",
}

export enum PokeFilterGenders {
  Couple = "couple",
  Female = "female",
  Male = "male",
  Transgender = "transgender",
  Unknown = "unknown",
}

export enum PokeFilterLastPaymentPeriods {
  MoreOneMonth = "older than 1 month",
  WithinOneMonth = "within 1 month",
  WithinTwoWeeks = "within 1 week",
  WithinOneWeek = "within 2 weeks",
}

export enum PokeFilterCreditStatuses {
  HasCredits = "has credits",
  HadCredits = "had credits",
  NeverHadCredits = "never had credits",
}

export enum PokeFilterPhotoStatuses {
  WithPhoto = "with photo",
  WithoutPhoto = "without photo",
}

export enum PokeFilterTextStatuses {
  WithText = "with text",
  WithoutText = "without text",
}

export enum PokeConversationStatuses {
  InConversation = "conversation",
  NotInConversation = "not in conversation",
}

export type TargetAutoCompleteItem = {
  uuid: string;
  name: string;
};

export type SelectedTargetsPerDomain = {
  domain: string;
  targets: TargetAutoCompleteItem[];
};

export type FilterPlatersByNameParams = {
  sites: string[];
  filters: {
    search_by_name: string;
  };
  profileCmsId: number;
};

export type LatestNormalChatsPayload = {
  maxAmountOfChats: number;
  selectedDomains: string[];
};
export type AbuseSearchPeriodOption = {
  value: number;
  label: string;
};

export enum TimeInMinutes {
  FIVE_MINUTES = 5,
  THIRTY_MINUTES = 30,
  ONE_HOUR = 60,
  THREE_HOURS = 180,
  SIX_HOURS = 360,
  TWELVE_HOURS = 720,
  ONE_DAY = 1440,
  TWO_DAYS = 2880,
}

export interface SystemNotification {
  id: string;
  createdBy: string;
  createdAt: Timestamp;
  message: string;
  icon: string;
  showUntilAt: Timestamp;
}

export interface SystemStoreState {
  currentSystemNotification: SystemNotification | null;
  systemNotifications: SystemNotification[];
}

export type AuthStoreState = {
  isUserInitialized: boolean;
  token: string;
  user: UserData;
  forceMfa: boolean;
  hasMfaEnabled: boolean;
  password: {
    hasReset: boolean;
    requestedAt: Date;
  };
  mfaVerificationId: string;
  tenant: string;
  resolver: any;
};

export type NotificationStoreState = {
  notifications: Notification[];
  isClaimedChatNotificationActive: boolean;
};

export type SocketStoreState = {
  isConnected: boolean;
};

export type PokeScheduleStoreState = {
  pokes: PokeDocument[];
  dailyPokes: PokeDocument[];
  origin: OriginType;
  scheduledProfiles: ScheduleProfileInterface;
};

export type PokeQueue = {
  name: string;
  shortName: string;
  state: number;
  stateName: string;
  tasksCount: number;
};

export type PokeQueueStoreState = {
  locations: string[];
  queue: PokeQueue[];
  selectedLocation: string;
};

export type PokeWizardStoreState = {
  action: string;
  domainConfigurations: DomainConfiguration[];
  domains: string[];
  filters: Record<string, any>;
  followUps: FollowUpPoke[];
  geoStrategy: string | null;
  id?: Branded<string, "PokeId">;
  frequency: {
    day: number;
    limit?: number;
  };
  legacyFilters: LegacyPokeFilters;
  legacyProfile: Profile | null;
  locale: string;
  mainPokeMessage: PokeMessage;
  origin: OriginType;
  originalPoke: PokeDocument | null;
  pagination: {
    itemsPerPage: number;
    page: number;
  };
  pokePlayerFilters: Record<string, any>;
  pokeType: PokeTypeName;
  profile: Profile | null;
  query: string;
  searchedFilters: Record<string, any>;
  searchedPlayers: Record<string, any>[];
  selectedProfiles: Profile[];
  strategy: PokeStrategyName;
};

export type ProfileStoreState = {
  entertainmentProfiles: Profile[];
  entertainmentProfilesCount: number;
  activeEntertainmentProfile: Profile;
  geoFreeEntertainmentProfileLocation: GeoFreeLocationApiResponse;
  activeCustomerProfile: Profile;
  entertainmentProfileFilters: any;
  customerProfilesCountPerDomain: DomainCount[];
  loadingCounts: boolean;
  playersPerDomain: {
    [site: string]: LegacyProfile[];
  };
};

export type TriggerSetting = {
  name: string;
  data: TriggerProperties;
};

export type TriggerProperties = {
  enabled: boolean;
  behaviour_filter?: string[];
  profiles_without_conversation?: boolean;
  probability_percentage: number;
  limit_count: number;
  limit_duration: number | string;
  min_message_delay: number;
  max_message_delay: number;
  max_message_per_day?: number;
  fallback_to_operator_queue?: boolean;
  always_send_to_operator_queue: boolean;
  interval_to_skip_trigger_in_active_conversation_in_minutes?: number;
  flow: string;
};

export type TriggerState = {
  triggers: TriggerSetting[];
  schema: Record<string, any>;
};

export type TotpErrorHandlers = Record<string, () => void>;
